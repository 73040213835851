<template>
  <div class="fixed-center text-center">
    <p>
      <img src="../assets/sad.svg" style="width:30vw;max-width:150px;" />
    </p>
    <p class="text-faded">Sorry, nothing here...<strong>(404)</strong></p>
    <b-button color="secondary" style="width:200px;" @click.prevent="$router.replace({ name: 'Home' })"
      >Go Home</b-button
    >
  </div>
</template>

<script>
export default {
  name: 'Error404'
}
</script>
